.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #F0F0F0;
}

.title {
  flex-shrink: 0;
  padding: 8px;
  /* Keep synced with what is used in chatbot's `ChatSeqViewMenu` */
  box-shadow: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
  font-size: 120%;
}

.chatView {
  flex: 1;
  overflow: hidden;
}
