@import "~@xflr6/chatbot/dist/styles.css";

.App {
  width: 100%;
  height: 100%;
}

.App * {
  box-sizing: border-box;
}
